import { AlertFilter } from '../models/alertFilter';
import { Chart } from '../models/chart';

export class DashboardConstants {
  public static dateRanges = ['Today', 'Hour', 'Week', 'Month'];
  public static defaultView = 'grid';
  public static defaultTimeRange = 'Hour';
  public static statusList = ['Inactive', 'Active', 'Disconnect'];
  public static RECORDS_PER_PAGE = 10;
  public static PAGE_NUMBER = 1;
  public static REDZONE = 'redzone';
  public static REDZONE_SERVICE = 'Red zone';
  public static defaultStatusList = [
    {
      id: -1,
      status: 'All',
    },
    {
      id: 0,
      status: 'Active',
    },
    {
      id: 1,
      status: 'Inactive',
    },
    {
      id: 2,
      status: 'Disconnected',
    },
  ];

  public static gatewayChartConfig: Chart = {
    labels: ['Active', 'Inactive', 'Disconnected'],
    datasets: [
      {
        data: [],
        backgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        borderColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        borderWidth: 1,
        hoverBorderColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        hoverBackgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        pointBackgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        pointBorderColor: ['#fff', '#fff', '#fff', '#C7D0D8'],
        pointHoverBackgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        pointHoverBorderColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
      },
    ],
  };

  public static cameraChartConfig: Chart = {
    labels: ['Active', 'Inactive', 'Disconnected'],
    datasets: [
      {
        data: [],
        backgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        borderColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        borderWidth: 1,
        hoverBorderColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        hoverBackgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        pointBackgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        pointBorderColor: ['#fff', '#fff', '#fff', '#C7D0D8'],
        pointHoverBackgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        pointHoverBorderColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
      },
    ],
  };

  public static voilationChartConfig: Chart = {
    labels: ['Reviewed', 'Not Reviewed'],
    datasets: [
      {
        data: [],
        backgroundColor: ['#3097A7', '#FEE58A'],
        borderColor: ['#3097A7', '#FEE58A'],
        borderWidth: 1,
        hoverBorderColor: ['#3097A7', '#FEE58A'],
        hoverBackgroundColor: ['#3097A7', '#FEE58A'],
        pointBackgroundColor: ['#3097A7', '#FEE58A'],
        pointBorderColor: ['#fff', '#fff', '#fff'],
        pointHoverBackgroundColor: ['#3097A7', '#FEE58A'],
        pointHoverBorderColor: ['#3097A7', '#FEE58A'],
      },
    ],
  };

  public static mockWorkflows = ['Red Zone', 'PPE'];
  public static dashabordChartAPIError = 'Error while receiving chart details.';
  public static dashabordAlertsAPIError = 'Error while receiving alert details.';
  public static dashabordAssetHierarchyAPIError = 'Error while receiving asset hierarchy details.';
  public static dashabordCountryAPIError = 'Error while receiving country details.';
  public static NOCAMERAEVENT = 'No events detected for';
  public static NOT_REVIEWED = 'Not Reviewed';

  public static RIG_DETAILS = {
    BLOCK_MOVING_UP: 'Block Moving Up',
    BLOCK_MOVING_DOWN: 'Block Moving Down',
    MUD_CIRCULATION: 'Mud Circulation',
    DRILLING_ROTATION: 'Drilling Rotation',
    REDZONE_ON: 'RedZone On',
    NA: 'NA',
  };

  public static defaultFilter: AlertFilter = {
    rigId: -1,
    workflow: '',
    gatewayStatus: -1,
    cameraStatus: -1,
    pageNumber: DashboardConstants.PAGE_NUMBER,
    recordsPerPage: DashboardConstants.RECORDS_PER_PAGE,
  };
  public static EVENTTYPE = ['Piperack', 'Catwalk', 'PPE', 'Red zone'];
  public static EVENTTYPELINECHART = this.EVENTTYPE;
  public static EVENTTYPELINECHARTFONT = "'SLBSans', 'Helvetica Neue', sans-serif";
  public static OFF = 'OFF';
  public static REDZONE_ICON = 'assets/icons/inside_redzone.svg';
  public static PPE_ICON = 'assets/icons/ppe_violations.svg';
  public static CATWALK_ICON = 'assets/icons/catwalk.svg';
  public static PIPERACK_ICON = 'assets/icons/catwalk.svg';
  public static DEFAULT = '-1';
  public static DEFAULT_PAGINATION = {
    pageIndex: 0,
    pageSize: DashboardConstants.RECORDS_PER_PAGE,
    length: DashboardConstants.PAGE_NUMBER,
  };
  public static REDZONE_DEFINITION = 'RedZone';

  public static PPE_WORKFLOW = 'ppe';
  public static CATWALK_WORKFLOW = 'catwalk';
  public static PIPERACK_WORKFLOW = 'piperack';
  public static REDZONE_LABEL = 'Rig floor Red zone state';
  public static PPE_LABEL = 'Missing';
  public static CATWALK_LABEL = 'Catwalk';
  public static PIPERACK_LABEL = 'Piperack';
  public static NO_RIG_DATA = 'Red zone static';
  public static PPE = 'PPE';
  public static BULOGIC_2 = 2;
}
