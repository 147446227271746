import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavItem } from '@slb-dls/angular-material/shared';
import { ADMIN, USER_ROUTERLINKS } from '../../../app/shared/constants/unit-constant';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss'],
})
export class UserSettingComponent implements OnInit {
  public routerLinks = USER_ROUTERLINKS;
  public secondaryNavigationItems: NavItem[];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    const isAdmin = sessionStorage.getItem('isAdmin') === 'true' ? true : false;
    this.secondaryNavigationItems = JSON.parse(JSON.stringify(this.routerLinks));

    /** Hide admin menu for evp-hse-- till the enable &disable email feature deployment */
    if (
      (!isAdmin && !environment.apiBaseUrl.includes('evp')) ||
      (environment.apiBaseUrl.includes('evp') &&
        (!environment.apiBaseUrl.includes('evp-eu') || (!isAdmin && environment.apiBaseUrl.includes('evp-eu'))))
    ) {
      this.secondaryNavigationItems = this.secondaryNavigationItems.filter(item => item.label !== ADMIN);
    }
    const url = document.location.href;
    const checkUrl = url.split('/')?.[3];
    this.secondaryNavigationItems.forEach((navItem: NavItem) => {
      navItem.active = checkUrl === navItem.routerLink ? true : false;
    });
    this.cd.detectChanges();
  }
}
